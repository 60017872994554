<template>
  <!--VListItem - Vuetify-->
  <v-tooltip
    :disabled="!active"
    :value="active"
    :nudge-right="50"
    max-width="400"
    color="primary"
    content-class="px-6 py-4"
    right
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        :href="href"
        :rel="href && href !== '#' ? 'noopener' : undefined"
        :target="href && href !== '#' ? '_blank' : undefined"
        :to="item.to"
        class="h-12"
        :class="[
          active && enableTooltip
            ? 'border-2 border-solid border-primary bg-primary'
            : '',
        ]"
        v-bind="attrs"
        v-on="on"
      >
        <!--VListItemIcon - Vuetify-->
        <v-list-item-icon v-if="item.icon">
          <v-icon v-text="item.icon" />
        </v-list-item-icon>
        <!--VListItemContent - Vuetify-->
        <v-list-item-content v-if="item.title" class="text-lg" v-text="item.title">
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-icon
      style="position: absolute"
      color="primary"
      class="-left-12 top-1/3 animate-bounce duration-150"
      large
    >
      mdi-undo
    </v-icon>
    <span v-html="tooltipContent" class="text-base text-color-4" />
    <div>
      <v-btn
        color="gray"
        class="float-right clear-right mt-2 pointer-events-auto"
        @click.stop="onClickNext"
      >
        Đã hiểu
      </v-btn>
    </div>
  </v-tooltip>
</template>

<script>
import Themeable from "vuetify/lib/mixins/themeable";

export default {
  name: "Item",

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        title: undefined,
        to: undefined,
      }),
    },
    tooltipContent: {
      type: String,
      default: "Tooltip",
    },
    enableTooltip: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    nextStep: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    href() {
      return this.item.href || (!this.item.to ? "#" : undefined);
    },
  },

  methods: {
    onClickNext() {
      this.$store.commit("setStepGuide", this.nextStep);
    },
  },
};
</script>

<style scoped>
.v-list-item__icon {
  margin: 12px 20px 12px 0px !important;
}
.v-tooltip__content.menuable__content__active {
  opacity: 1;
}
</style>
